<template>
  <div class="aside-filter">
    <div class="aside-filter-container">
      <!-- 大分類 - 公司 -->
      <div class="aside-filter__box">
        <div class="aside-filter-title">{{dataType}}</div>
        <!-- <div class="aside-filter-content">
          <el-checkbox v-model="filterSampleCheckbox">選項一選項一選項一選項一選項一選項一選項一選項一選項一選項一<div class="count">9999</div></el-checkbox>
          <el-checkbox v-model="filterSampleCheckbox">選項二選項二選項二選項二選項二選項二選項二選項二選項二選項二<div class="count">1</div></el-checkbox>
          <el-button type="text" size="mini">更多</el-button>
        </div> -->
        <div
          class="aside-filter-content"
          v-loading="isPageLoading">
          <!-- 1.公司狀態 -->
          <div class="filter-group">
            <div class="filter-group-title">{{FILTER_ITEM_DICT.company_state}}</div>
            <el-tree
              ref="companyState"
              show-checkbox
              node-key="id"
              :data="companyStateOptions"
              :props="defaultProps">
              <span class="aside-filter-tree-node" slot-scope="{ node, data }">
                <span class="label">{{ data.label }}</span>
                <span class="count">{{ data.source.doc_count }}</span>
              </span>
            </el-tree>
          </div>
          <!-- 營業狀態 -->
          <div class="filter-group">
            <div class="filter-group-title">{{FILTER_ITEM_DICT.company_operate}}</div>
            <el-tree
              ref="companyOperate"
              show-checkbox
              node-key="id"
              :data="companyOperateOptions"
              :props="defaultProps">
              <span class="aside-filter-tree-node" slot-scope="{ node, data }">
                <span class="label">{{ data.label }}</span>
                <span class="count">{{ data.source.doc_count }}</span>
              </span>
            </el-tree>
          </div>
          <!-- 2.所在地區 -->
          <div class="filter-group">
            <div class="filter-group-title">{{FILTER_ITEM_DICT.company_county}}</div>
            <el-tree
              ref="companyCounty"
              show-checkbox
              node-key="id"
              :data="companyCountyOptions"
              :props="defaultProps">
              <span class="aside-filter-tree-node" slot-scope="{ node, data }">
                <span class="label">{{ data.label }}</span>
                <span class="count">{{ data.source.doc_count }}</span>
              </span>
            </el-tree>
          </div>
          <!-- 3.成立年限 -->
          <div class="filter-group">
            <div class="filter-group-title">{{FILTER_ITEM_DICT.date_approved}}</div>
            <!-- <el-tree
              ref="dateApproved"
              show-checkbox
              node-key="id"
              :data="dateApprovedOptions"
              :props="defaultProps">
              <span class="aside-filter-tree-node" slot-scope="{ node, data }">
                <span class="label">{{ data.label }}</span>
                <span class="count">{{ data.source.doc_count }}</span>
              </span>
            </el-tree> -->
            <div v-if="dateApprovedSliderOptions&&dateApprovedSliderOptions.length">
              <el-row>
                <el-col :span="9">
                  <el-select
                    class="slider-value-selector"
                    placeholder="最小值"
                    v-model="dateApprovedSliderValue[0]">
                    <el-option
                      v-for="(item,index) in dateApprovedSliderOptions"
                      :key="item.label"
                      :label="item.label"
                      :value="index">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col style="color:#606266;font-size:13px;text-align:center;line-height:30px" :span="4">
                  至
                </el-col>
                <el-col :span="11">
                  <el-select
                    class="slider-value-selector"
                    placeholder="最大值"
                    v-model="dateApprovedSliderValue[1]">
                    <el-option
                      v-for="(item,index) in dateApprovedSliderOptions"
                      :key="item.label"
                      :label="item.label"
                      :value="index">
                    </el-option>
                  </el-select>
                </el-col>
                <!-- {{dateApprovedSliderOptions[dateApprovedSliderValue[0]].label}} - {{dateApprovedSliderOptions[dateApprovedSliderValue[1]].label}} -->
              </el-row>
              <el-row>
                <el-slider
                  v-model="dateApprovedSliderValue"
                  range
                  show-stops
                  :class="{inactive:isDataApprovedSliderInactive}"
                  :show-tooltip="false"
                  :min="0"
                  :max="dateApprovedSliderMax"
                  :disabled="dateApprovedSliderOptions.length<=1">
                </el-slider>
              </el-row>
            </div>
            <div v-else>
              <el-tree :data="[]">
              </el-tree>
            </div>
          </div>
          <!-- 4.資本總額 -->
          <div class="filter-group">
            <div class="filter-group-title">{{FILTER_ITEM_DICT.capital_amount}}</div>
            <!-- <el-tree
              ref="capitalAmount"
              show-checkbox
              node-key="id"
              :data="capitalAmountOptions"
              :props="defaultProps">
              <span class="aside-filter-tree-node" slot-scope="{ node, data }">
                <span class="label">{{ data.label }}</span>
                <span class="count">{{ data.source.doc_count }}</span>
              </span>
            </el-tree> -->
            <div v-if="capitalAmountSliderOptions&&capitalAmountSliderOptions.length">
              <el-row>
                <el-col :span="9">
                  <el-select
                    class="slider-value-selector"
                    placeholder="最小值"
                    v-model="capitalAmountSliderValue[0]">
                    <el-option
                      v-for="(item,index) in capitalAmountSliderOptions"
                      :key="item.label"
                      :label="item.label"
                      :value="index">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col style="color:#606266;font-size:13px;text-align:center;line-height:30px" :span="4">
                  至
                </el-col>
                <el-col :span="11">
                  <el-select
                    class="slider-value-selector"
                    placeholder="最大值"
                    v-model="capitalAmountSliderValue[1]">
                    <el-option
                      v-for="(item,index) in capitalAmountSliderOptions"
                      :key="item.label"
                      :label="item.label"
                      :value="index">
                    </el-option>
                  </el-select>
                </el-col>
                <!-- {{capitalAmountSliderOptions[capitalAmountSliderValue[0]].label}} - {{capitalAmountSliderOptions[capitalAmountSliderValue[1]].label}} -->
              </el-row>
              <el-row>
                <el-slider
                  v-model="capitalAmountSliderValue"
                  range
                  show-stops
                  :class="{inactive:isCapitalAmountSliderInactive}"
                  :show-tooltip="false"
                  :min="0"
                  :max="capitalAmountSliderMax"
                  :disabled="capitalAmountSliderOptions.length<=1">
                </el-slider>
              </el-row>
            </div>
            <div v-else>
              <el-tree :data="[]">
              </el-tree>
            </div>
          </div>
          <!-- 5.員工人數 -->
          <!-- 6.股權狀況 -->
          <div class="filter-group">
            <div class="filter-group-title">{{FILTER_ITEM_DICT.stock_state}}</div>
            <el-tree
              ref="stockState"
              show-checkbox
              node-key="id"
              :data="stockStateOptions"
              :props="defaultProps">
              <span class="aside-filter-tree-node" slot-scope="{ node, data }">
                <span class="label">{{ data.label }}</span>
                <span class="count">{{ data.source.doc_count }}</span>
              </span>
            </el-tree>
          </div>
          <!-- 7.公開發行 -->
          <div class="filter-group">
            <div class="filter-group-title">{{FILTER_ITEM_DICT.public_issue}}</div>
            <el-tree
              ref="publicIssue"
              show-checkbox
              node-key="id"
              :data="publicIssueOptions"
              :props="defaultProps">
              <span class="aside-filter-tree-node" slot-scope="{ node, data }">
                <span class="label">{{ data.label }}</span>
                <span class="count">{{ data.source.doc_count }}</span>
              </span>
            </el-tree>
          </div>
          <!-- 8.最近一年度總進口實績 -->
          <div class="filter-group">
            <div class="filter-group-title">{{FILTER_ITEM_DICT.imports}}</div>
            <!-- <el-tree
              ref="imports"
              show-checkbox
              node-key="id"
              :data="importsOptions"
              :props="defaultProps">
              <span class="aside-filter-tree-node" slot-scope="{ node, data }">
                <span class="label">{{ data.label }}</span>
                <span class="count">{{ data.source.doc_count }}</span>
              </span>
            </el-tree> -->
            <div v-if="importsSliderOptions&&importsSliderOptions.length">
              <el-row>
                <el-col :span="9">
                  <el-select
                    class="slider-value-selector"
                    placeholder="最小值"
                    v-model="importsSliderValue[0]">
                    <el-option
                      v-for="(item,index) in importsSliderOptions"
                      :key="item.label"
                      :label="item.label"
                      :value="index">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col style="color:#606266;font-size:13px;text-align:center;line-height:30px" :span="4">
                  至
                </el-col>
                <el-col :span="11">
                  <el-select
                    class="slider-value-selector"
                    placeholder="最大值"
                    v-model="importsSliderValue[1]">
                    <el-option
                      v-for="(item,index) in importsSliderOptions"
                      :key="item.label"
                      :label="item.label"
                      :value="index">
                    </el-option>
                  </el-select>
                </el-col>
                <!-- {{importsSliderOptions[importsSliderValue[0]].label}} - {{importsSliderOptions[importsSliderValue[1]].label}} -->
              </el-row>
              <el-row>
                <el-slider
                  v-model="importsSliderValue"
                  range
                  show-stops
                  :class="{inactive:isImportsSliderInactive}"
                  :show-tooltip="false"
                  :min="0"
                  :max="importsSliderMax"
                  :disabled="importsSliderOptions.length<=1">
                </el-slider>
              </el-row>
            </div>
            <div v-else>
              <el-tree :data="[]">
              </el-tree>
            </div>
          </div>
          <!-- 9.最近一年度總出口實績 -->
          <div class="filter-group">
            <div class="filter-group-title">{{FILTER_ITEM_DICT.exports}}</div>
            <!-- <el-tree
              ref="exports"
              show-checkbox
              node-key="id"
              :data="exportsOptions"
              :props="defaultProps">
              <span class="aside-filter-tree-node" slot-scope="{ node, data }">
                <span class="label">{{ data.label }}</span>
                <span class="count">{{ data.source.doc_count }}</span>
              </span>
            </el-tree> -->
            <div v-if="exportsSliderOptions&&exportsSliderOptions.length">
              <el-row>
                <el-col :span="9">
                  <el-select
                    class="slider-value-selector"
                    placeholder="最小值"
                    v-model="exportsSliderValue[0]">
                    <el-option
                      v-for="(item,index) in exportsSliderOptions"
                      :key="'exports_'+item.label"
                      :label="item.label"
                      :value="index">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col style="color:#606266;font-size:13px;text-align:center;line-height:30px" :span="4">
                  至
                </el-col>
                <el-col :span="11">
                  <el-select
                    class="slider-value-selector"
                    placeholder="最大值"
                    v-model="exportsSliderValue[1]">
                    <el-option
                      v-for="(item,index) in exportsSliderOptions"
                      :key="'exports_'+item.label"
                      :label="item.label"
                      :value="index">
                    </el-option>
                  </el-select>
                </el-col>
                <!-- {{exportsSliderOptions[exportsSliderValue[0]].label}} - {{exportsSliderOptions[exportsSliderValue[1]].label}} -->
              </el-row>
              <el-row>
                <el-slider
                  v-model="exportsSliderValue"
                  range
                  show-stops
                  :class="{inactive:isExportsSliderInactive}"
                  :show-tooltip="false"
                  :min="0"
                  :max="exportsSliderMax"
                  :disabled="exportsSliderOptions.length<=1">
                </el-slider>
              </el-row>
            </div>
            <div v-else>
              <el-tree :data="[]">
              </el-tree>
            </div>
          </div>
          <!-- 10.最近一個月得標金額 -->
          <div class="filter-group">
            <div class="filter-group-title">{{FILTER_ITEM_DICT.procurement_history}}</div>
            <!-- <el-tree
              ref="procurement_history"
              show-checkbox
              node-key="id"
              :data="procurementHistoryOptions"
              :props="defaultProps">
              <span class="aside-filter-tree-node" slot-scope="{ node, data }">
                <span class="label">{{ data.label }}</span>
                <span class="count">{{ data.source.doc_count }}</span>
              </span>
            </el-tree> -->
            <div v-if="procurementHistorySliderOptions&&procurementHistorySliderOptions.length">
              <el-row>
                <el-col :span="9">
                  <el-select
                    class="slider-value-selector"
                    placeholder="最小值"
                    v-model="procurementHistorySliderValue[0]">
                    <el-option
                      v-for="(item,index) in procurementHistorySliderOptions"
                      :key="'procurement_history_'+item.label"
                      :label="item.label"
                      :value="index">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col style="color:#606266;font-size:13px;text-align:center;line-height:30px" :span="4">
                  至
                </el-col>
                <el-col :span="11">
                  <el-select
                    class="slider-value-selector"
                    placeholder="最大值"
                    v-model="procurementHistorySliderValue[1]">
                    <el-option
                      v-for="(item,index) in procurementHistorySliderOptions"
                      :key="'procurement_history_'+item.label"
                      :label="item.label"
                      :value="index">
                    </el-option>
                  </el-select>
                </el-col>
                <!-- {{procurementHistoryOptions[procurementHistorySliderValue[0]].label}} - {{procurementHistoryOptions[procurementHistorySliderValue[1]].label}} -->
              </el-row>
              <el-row>
                <el-slider
                  v-model="procurementHistorySliderValue"
                  range
                  show-stops
                  :class="{inactive:isProcurementHistorySliderInactive}"
                  :show-tooltip="false"
                  :min="0"
                  :max="procurementHistorySliderMax"
                  :disabled="procurementHistorySliderOptions.length<=1">
                </el-slider>
              </el-row>
            </div>
            <div v-else>
              <el-tree :data="[]">
              </el-tree>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="aside-filter__box">
        <div class="aside-filter-title">另一種簡單形式</div>
        <div class="aside-filter-content">
          <el-checkbox class="one-level" v-model="filterSampleCheckbox">選項一<div class="count">1</div></el-checkbox>
          <el-checkbox class="one-level" v-model="filterSampleCheckbox">選項二<div class="count">1</div></el-checkbox>
          <el-button type="text" size="mini">更多</el-button>
        </div>
      </div> -->
    </div>
    <div class="aside-filter-footer">
      <el-button
        type="primary"
        :disabled="companyCount<=0"
        @click="search">
        篩選
      </el-button>
      <el-button type="text" @click="resetAdvSearch">
        清除篩選條件
      </el-button>
    </div>
  </div>
</template>

<script>
import routerService from '@/utils/routerService.js'
import moment from 'moment'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import * as codes from '@/const/codes.ts'
const currentYear = moment().year()

export default {
  props: ['dataType'],
  data () {
    return {
      FILTER_ITEM_DICT: codes.FILTER_ITEM_DICT,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      dateApprovedSliderValue: [0, 0],
      capitalAmountSliderValue: [0, 0],
      importsSliderValue: [0, 0],
      exportsSliderValue: [0, 0],
      procurementHistorySliderValue: [0, 0]
    }
  },
  computed: {
    ...mapState([
      'originRouteQuery'
    ]),
    ...mapState('companyList', [
      'companyCount',
      'isPageLoading',
      'companyStateOptions',
      'companyOperateOptions',
      'companyCountyOptions',
      'dateApprovedOptions',
      'dateApprovedSliderOptions',
      'capitalAmountOptions',
      'capitalAmountSliderOptions',
      'stockStateOptions',
      'publicIssueOptions',
      'importsOptions',
      'importsSliderOptions',
      'exportsOptions',
      'exportsSliderOptions',
      'procurementHistoryOptions',
      'procurementHistorySliderOptions'
      // 'companyOperateOptions',
      // 'dataTypeOptions'
    ]),
    currentDataType () {
      if (this.$route.params.dataTypeIndex === 'default') {
        return '公司'
      } else if (this.$route.params.dataTypeIndex === codes.DATA_TYPE_INDEX_DIST['公司']) {
        return '公司'
      } else {
        return '商業登記'
      }
    },
    dateApprovedSliderMax () {
      if (this.dateApprovedSliderOptions && this.dateApprovedSliderOptions.length) {
        return this.dateApprovedSliderOptions.length - 1
      } else {
        return 0
      }
    },
    capitalAmountSliderMax () {
      if (this.capitalAmountSliderOptions && this.capitalAmountSliderOptions.length) {
        return this.capitalAmountSliderOptions.length - 1
      } else {
        return 0
      }
    },
    importsSliderMax () {
      if (this.importsSliderOptions && this.importsSliderOptions.length) {
        return this.importsSliderOptions.length - 1
      } else {
        return 0
      }
    },
    exportsSliderMax () {
      if (this.exportsSliderOptions && this.exportsSliderOptions.length) {
        return this.exportsSliderOptions.length - 1
      } else {
        return 0
      }
    },
    procurementHistorySliderMax () {
      if (this.procurementHistorySliderOptions && this.procurementHistorySliderOptions.length) {
        return this.procurementHistorySliderOptions.length - 1
      } else {
        return 0
      }
    },
    isDataApprovedSliderInactive () {
      if (this.dateApprovedSliderValue[0] === 0 &&
      this.dateApprovedSliderValue[1] === this.dateApprovedSliderOptions.length - 1) {
        return true
      } else {
        return false
      }
    },
    isCapitalAmountSliderInactive () {
      if (this.capitalAmountSliderValue[0] === 0 &&
      this.capitalAmountSliderValue[1] === this.capitalAmountSliderOptions.length - 1) {
        return true
      } else {
        return false
      }
    },
    isImportsSliderInactive () {
      if (this.importsSliderValue[0] === 0 &&
      this.importsSliderValue[1] === this.importsSliderOptions.length - 1) {
        return true
      } else {
        return false
      }
    },
    isExportsSliderInactive () {
      if (this.exportsSliderValue[0] === 0 &&
      this.exportsSliderValue[1] === this.exportsSliderOptions.length - 1) {
        return true
      } else {
        return false
      }
    },
    isProcurementHistorySliderInactive () {
      if (this.procurementHistorySliderValue[0] === 0 &&
      this.procurementHistorySliderValue[1] === this.procurementHistorySliderOptions.length - 1) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    search () {
      const self = this
      const query = JSON.parse(JSON.stringify(this.originRouteQuery))
      // let filterQuery = [] // 查詢條件
      // let infoTextArr = [] // 顯示查詢條件文字

      // -- 查詢 --
      // 目前的查詢query加上filter
      let currentQueryData = JSON.parse(query.currentQuery)
      if (!currentQueryData.filter) {
        currentQueryData.filter = []
      }

      // 取得選單資料
      let dataApprovedData = returnDataApprovedData()
      if (dataApprovedData.query.length) {
        currentQueryData.filter.push(dataApprovedData)
      }
      let capitalAmountData = returnCapitalAmountData()
      if (capitalAmountData.query.length) {
        currentQueryData.filter.push(capitalAmountData)
      }
      let importsData = returnImportsData()
      if (importsData.query.length) {
        currentQueryData.filter.push(importsData)
      }
      let exportsData = returnExportsData()
      if (exportsData.query.length) {
        currentQueryData.filter.push(exportsData)
      }
      let procurementHistoryData = returnProcurementHistoryData()
      if (procurementHistoryData.query.length) {
        currentQueryData.filter.push(procurementHistoryData)
      }
      let companyStateData = returnCompanyStateData()
      if (companyStateData.query.length) {
        currentQueryData.filter.push(companyStateData)
      }
      let companyOperateData = returnCompanyOperateData()
      if (companyOperateData.query.length) {
        currentQueryData.filter.push(companyOperateData)
      }
      let companyCountyData = returnCompanyCountyData()
      if (companyCountyData.query.length) {
        currentQueryData.filter.push(companyCountyData)
      }
      let stockStateData = returnStockState()
      if (stockStateData.query.length) {
        currentQueryData.filter.push(stockStateData)
      }
      let publicIssueData = returnPublicIssue()
      if (publicIssueData.query.length) {
        currentQueryData.filter.push(publicIssueData)
      }
      
      // query轉字串
      query.currentQuery = JSON.stringify(currentQueryData)
      // router
      // this.$router.push({
      routerService('push', {
        name: 'companyList',
        query
      })

      // -- slider選單（取得在範圍之內的值） --
      // date_approved
      function returnDataApprovedData () {
        if (self.isDataApprovedSliderInactive === true) {
          return {
            method: 'should',
            query: [],
            _desc: ''
          }
        }
        let filterQuery = [] // 查詢條件
        let infoTextArr = [] // 顯示查詢條件文字
        let dateApprovedMin = 0
        let dateApprovedMax = Infinity
        if (self.dateApprovedSliderOptions.length) {
          dateApprovedMin = self.dateApprovedSliderOptions[self.dateApprovedSliderValue[0]].value
          dateApprovedMax = self.dateApprovedSliderOptions[self.dateApprovedSliderValue[1]].value
        }
        let dateApprovedInfoTextArr = []
        self.dateApprovedOptions.forEach(d => {
          let itemMin = 0
          if (d.source.to_as_string) {
            itemMin = currentYear - Number(d.source.to_as_string.slice(0, 4))
          }
          let itemMax = Infinity
          if (d.source.from_as_string) {
            itemMax = currentYear - Number(d.source.from_as_string.slice(0, 4))
          }
          if (itemMin >= dateApprovedMin && itemMax <= dateApprovedMax) {
            filterQuery.push(d.source.queries)
            dateApprovedInfoTextArr.push(d.source.key)
          }
        })
        if (dateApprovedInfoTextArr.length) {
          const text = dateApprovedInfoTextArr.join('、')
          infoTextArr.push(`${codes.FILTER_ITEM_DICT.date_approved}(${text})`)
        }

        return {
          method: 'should',
          query: filterQuery,
          _desc: infoTextArr.join('、') // 查詢條件說明文字
        }
      }
      // capital_amount
      function returnCapitalAmountData () {
        if (self.isCapitalAmountSliderInactive === true) {
          return {
            method: 'should',
            query: [],
            _desc: ''
          }
        }
        let filterQuery = [] // 查詢條件
        let infoTextArr = [] // 顯示查詢條件文字
        let capitalAmountMin = 0
        let capitalAmountMax = Infinity
        if (self.capitalAmountSliderOptions.length) {
          capitalAmountMin = self.capitalAmountSliderOptions[self.capitalAmountSliderValue[0]].value
          capitalAmountMax = self.capitalAmountSliderOptions[self.capitalAmountSliderValue[1]].value
        }
        let capitalAmountTextArr = []
        self.capitalAmountOptions.forEach(d => {
          const itemMin = d.source.from || 0
          const itemMax = d.source.to || Infinity
          if (itemMin >= capitalAmountMin && itemMax <= capitalAmountMax) {
            filterQuery.push(d.source.queries)
            capitalAmountTextArr.push(d.source.key)
          }
        })
        if (capitalAmountTextArr.length) {
          const text = capitalAmountTextArr.join('、')
          infoTextArr.push(`${codes.FILTER_ITEM_DICT.capital_amount}(${text})`)
        }
        return {
          method: 'should',
          query: filterQuery,
          _desc: infoTextArr.join('、') // 查詢條件說明文字
        }
      }
      // imports
      function returnImportsData () {
        if (self.isImportsSliderInactive === true) {
          return {
            method: 'should',
            query: [],
            _desc: ''
          }
        }
        let filterQuery = [] // 查詢條件
        let infoTextArr = [] // 顯示查詢條件文字
        let importsMin = 0
        let importsMax = Infinity
        if (self.importsSliderOptions.length) {
          importsMin = self.importsSliderOptions[self.importsSliderValue[0]].value
          importsMax = self.importsSliderOptions[self.importsSliderValue[1]].value
        }
        let importsTextArr = []
        self.importsOptions.forEach(d => {
          const item = codes.IMPORT_AND_EXPORT_DATA[d.source.key]
          if (item) {
            if (item.min >= importsMin && item.max <= importsMax) {
              filterQuery.push(d.source.queries)
              const text = codes.IMPORT_AND_EXPORT_DATA[d.source.key].label // 轉換代碼
              importsTextArr.push(text)
            }
          }
        })
        if (importsTextArr.length) {
          const text = importsTextArr.join('、')
          infoTextArr.push(`${codes.FILTER_ITEM_DICT.imports}(${text})`)
        }
        return {
          method: 'should',
          query: filterQuery,
          _desc: infoTextArr.join('、') // 查詢條件說明文字
        }
      }
      // exports
      function returnExportsData () {
        if (self.isExportsSliderInactive === true) {
          return {
            method: 'should',
            query: [],
            _desc: ''
          }
        }
        let filterQuery = [] // 查詢條件
        let infoTextArr = [] // 顯示查詢條件文字
        let exportsMin = 0
        let exportsMax = Infinity
        if (self.exportsSliderOptions.length) {
          exportsMin = self.exportsSliderOptions[self.exportsSliderValue[0]].value
          exportsMax = self.exportsSliderOptions[self.exportsSliderValue[1]].value
        }
        let exportsTextArr = []
        self.exportsOptions.forEach(d => {
          const item = codes.IMPORT_AND_EXPORT_DATA[d.source.key]
          if (item) {
            if (item.min >= exportsMin && item.max <= exportsMax) {
              filterQuery.push(d.source.queries)
              const text = codes.IMPORT_AND_EXPORT_DATA[d.source.key].label // 轉換代碼
              exportsTextArr.push(text)
            }
          }
        })
        if (exportsTextArr.length) {
          const text = exportsTextArr.join('、')
          infoTextArr.push(`${codes.FILTER_ITEM_DICT.exports}(${text})`)
        }
        return {
          method: 'should',
          query: filterQuery,
          _desc: infoTextArr.join('、') // 查詢條件說明文字
        }
      }
      // procurement_history
      function returnProcurementHistoryData () {
        if (self.isProcurementHistorySliderInactive === true) {
          return {
            method: 'should',
            query: [],
            _desc: ''
          }
        }
        let filterQuery = [] // 查詢條件
        let infoTextArr = [] // 顯示查詢條件文字
        let procurementHistoryMin = 0
        let procurementHistoryMax = Infinity
        if (self.procurementHistorySliderOptions.length) {
          procurementHistoryMin = self.procurementHistorySliderOptions[self.procurementHistorySliderValue[0]].value
          procurementHistoryMax = self.procurementHistorySliderOptions[self.procurementHistorySliderValue[1]].value
        }
        let procurementHistoryTextArr = []
        self.procurementHistoryOptions.forEach(d => {
          const itemMin = d.source.from || 0
          const itemMax = d.source.to || Infinity
          if (itemMin >= procurementHistoryMin && itemMax <= procurementHistoryMax) {
            filterQuery.push(d.source.queries)
            procurementHistoryTextArr.push(d.source.key)
          }
        })
        if (procurementHistoryTextArr.length) {
          const text = procurementHistoryTextArr.join('、')
          infoTextArr.push(`${codes.FILTER_ITEM_DICT.procurement_history}(${text})`)
        }
        return {
          method: 'should',
          query: filterQuery,
          _desc: infoTextArr.join('、') // 查詢條件說明文字
        }
      }
      // -- 樹狀選單 --
      // const capitalAmount = this.$refs.capitalAmount.getCheckedNodes()
      // 取得已勾選的查詢條件
      // company_state
      function returnCompanyStateData () {
        // 取得已勾選tree nodes
        const companyStateNodes = self.$refs.companyState.getCheckedNodes()
        let filterQuery = [] // 查詢條件
        let infoTextArr = [] // 顯示查詢條件文字
        let companyStateTextArr = []
        companyStateNodes.forEach(d => {
          if (d.source.queries) {
            filterQuery.push(d.source.queries)
            companyStateTextArr.push(d.source.key)
          }
        })
        if (companyStateTextArr.length) {
          const text = companyStateTextArr.join('、')
          infoTextArr.push(`${codes.FILTER_ITEM_DICT.procurement_history}(${text})`)
        }
        return {
          method: 'should',
          query: filterQuery,
          _desc: infoTextArr.join('、') // 查詢條件說明文字
        }
      }
      // company_operate
      function returnCompanyOperateData () {
        // 取得已勾選tree nodes
        const companyOperate = self.$refs.companyOperate.getCheckedNodes()
        let filterQuery = [] // 查詢條件
        let infoTextArr = [] // 顯示查詢條件文字
        let companyOperateTextArr = []
        companyOperate.forEach(d => {
          if (d.source.queries) {
            filterQuery.push(d.source.queries)
            companyOperateTextArr.push(d.source.key)
          }
        })
        if (companyOperateTextArr.length) {
          const text = companyOperateTextArr.join('、')
          infoTextArr.push(`${codes.FILTER_ITEM_DICT.company_operate}(${text})`)
        }
        return {
          method: 'should',
          query: filterQuery,
          _desc: infoTextArr.join('、') // 查詢條件說明文字
        }
      }
      // company_county
      function returnCompanyCountyData () {
        // 取得已勾選tree nodes
        const companyCounty = self.$refs.companyCounty.getCheckedNodes()
        let filterQuery = [] // 查詢條件
        let infoTextArr = [] // 顯示查詢條件文字
        let companyCountyTextArr = []
        companyCounty.forEach(d => {
          if (d.source.queries) {
            filterQuery.push(d.source.queries)
            companyCountyTextArr.push(d.source.key)
          }
        })
        if (companyCountyTextArr.length) {
          const text = companyCountyTextArr.join('、')
          infoTextArr.push(`${codes.FILTER_ITEM_DICT.company_county}(${text})`)
        }
        return {
          method: 'should',
          query: filterQuery,
          _desc: infoTextArr.join('、') // 查詢條件說明文字
        }
      }
      // capitalAmount.forEach(d => {
      //   if (d.source.queries) {
      //     filterQuery.push(d.source.queries)
      //   }
      // })
      // stock_state
      function returnStockState () {
        // 取得已勾選tree nodes
        const stockState = self.$refs.stockState.getCheckedNodes()
        let filterQuery = [] // 查詢條件
        let infoTextArr = [] // 顯示查詢條件文字
        let stockStateTextArr = []
        stockState.forEach(d => {
          if (d.source.queries) {
            filterQuery.push(d.source.queries)
            stockStateTextArr.push(d.source.key)
          }
        })
        if (stockStateTextArr.length) {
          const text = stockStateTextArr.join('、')
          infoTextArr.push(`${codes.FILTER_ITEM_DICT.stock_state}(${text})`)
        }
        return {
          method: 'should',
          query: filterQuery,
          _desc: infoTextArr.join('、') // 查詢條件說明文字
        }
      }
      // public_issue
      function returnPublicIssue () {
        // 取得已勾選tree nodes
        const publicIssue = self.$refs.publicIssue.getCheckedNodes()
        let filterQuery = [] // 查詢條件
        let infoTextArr = [] // 顯示查詢條件文字
        let publicIssueTextArr = []
        publicIssue.forEach(d => {
          if (d.source.queries) {
            filterQuery.push(d.source.queries)
            publicIssueTextArr.push(d.source.key)
          }
        })
        if (publicIssueTextArr.length) {
          const text = publicIssueTextArr.join('、')
          infoTextArr.push(`${codes.FILTER_ITEM_DICT.public_issue}(${text})`)
        }
        return {
          method: 'should',
          query: filterQuery,
          _desc: infoTextArr.join('、') // 查詢條件說明文字
        }
      }

      // -- 查詢 --
      // 目前的查詢query加上filter
      // let currentQueryData = JSON.parse(query.currentQuery)
      // if (!currentQueryData.filter) {
      //   currentQueryData.filter = []
      // }
      // currentQueryData.filter.push({
      //   method: 'should',
      //   query: filterQuery,
      //   _desc: infoTextArr.join('、') // 查詢條件說明文字
      // })
      // // query轉字串
      // query.currentQuery = JSON.stringify(currentQueryData)
      // // router
      // this.$router.push({
      //   name: 'companyList',
      //   query
      // })
    },
    resetAdvSearch () {
      let query = JSON.parse(JSON.stringify(this.originRouteQuery))
      // 將目前的current還原為首頁設定的條件
      query.currentQuery = JSON.parse(JSON.stringify(this.originRouteQuery.originQuery))
      routerService('push', {
        name: 'companyList',
        params: {
          dataTypeIndex: codes.DATA_TYPE_INDEX_DIST[this.currentDataType] // 修改為所點選的data_type
        },
        query
      })
    }
  },
  watch: {
    'dateApprovedSliderOptions': {
      handler (d) {
        if (d && d.length) {
          this.dateApprovedSliderValue = [0, d.length - 1]
        } else {
          this.dateApprovedSliderValue = [0, 0]
        }
      },
      immediate: true
    },
    'capitalAmountSliderOptions': {
      handler (d) {
        if (d && d.length) {
          this.capitalAmountSliderValue = [0, d.length - 1]
        } else {
          this.capitalAmountSliderValue = [0, 0]
        }
      },
      immediate: true
    },
    'importsSliderOptions': {
      handler (d) {
        if (d && d.length) {
          this.importsSliderValue = [0, d.length - 1]
        } else {
          this.importsSliderValue = [0, 0]
        }
      },
      immediate: true
    },
    'exportsSliderOptions': {
      handler (d) {
        if (d && d.length) {
          this.exportsSliderValue = [0, d.length - 1]
        } else {
          this.exportsSliderValue = [0, 0]
        }
      },
      immediate: true
    },
    'procurementHistorySliderOptions': {
      handler (d) {
        if (d && d.length) {
          this.procurementHistorySliderValue = [0, d.length - 1]
        } else {
          this.procurementHistorySliderValue = [0, 0]
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-select.slider-value-selector {
  .el-input--suffix .el-input__inner {
    height: 30px;
    padding-left: 5px;
    padding-right: 0px;
  }
  i {
    display: none;
  }
}

/deep/ .el-input__inner {
  font-size: 13px;
}

/deep/ .el-slider {
  &.active {
    .el-slider__bar {
      background-color: #409eff;
    }
    .el-slider__button-wrapper .el-slider__button{
      border: solid 2px #1778f5;
    }
  }
  &.inactive {
    .el-slider__bar {
      background-color: #909399;
    }
    .el-slider__button-wrapper .el-slider__button{
      border: solid 2px #909399;
    }
  }
}

</style>
