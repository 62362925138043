<template>
  <ul
    class="company-list"
    v-if="isShowCompanyList">
    <CompanyCard
      v-for="company in companyList"
      :key="company.uniID"
      :company="company"
      :queryCondition="queryCondition">
      <template #collectionButton="{ company }">
        <el-button
          type="text"
          class="heart"
          @click="toggleCollection(company)">
          <font-awesome-icon
            :icon="[ 'fas', 'heart' ]"
            :class="heartClass(company.uniID)"/>
        </el-button>
      </template>
    </CompanyCard>
  </ul>
</template>

<script>
import { mapState } from 'vuex'
import * as apis from '@/apis/index.ts'
import CompanyCard from '../CompanyCard/CompanyCard.vue'

export default {
  props: {
    'companyList': {
      type: Array,
      default () {
        return []
      }
    },
    // 查詢條件
    'queryCondition': {
      type: Object
    },
    // 加入搜藏對應表
    'isCollections': {
      type: Object,
      default () {
        return {}
      }
    }
  },
  components: {
    CompanyCard
  },
  data () {
    return {
      currentIsCollections: {}, // component私有的isCollections
      isShowCompanyList: true
    }
  },
  computed: {
    ...mapState([
      'userInfo'
    ]),
  },
  methods: {
    toggleCollection (company) {
      this.$set(this.currentIsCollections, company.uniID, !this.currentIsCollections[company.uniID])
      if (this.currentIsCollections[company.uniID] === true) {
        apis.collectionAdd({
          email: this.userInfo.email,
          company_uniID: company.uniID,
          company_name: company.company_name,
          note: ''
        })
      } else {
        apis.collectionsDelete({
          email: this.userInfo.email,
          companys: [company.uniID]
        })
      }
    },
    heartClass (uniID) {
      let isCollection = false
      if (this.currentIsCollections[uniID] != null) {
        isCollection = this.currentIsCollections[uniID]
      }
      if (isCollection == true) {
        return 'color-danger'
      } else {
        return 'color-gray'
      }
    }
  },
  watch: {
    'isCollections': {
      handler (d) {
        // 複製到私有變數
        this.currentIsCollections = JSON.parse(JSON.stringify(d))
      },
      immediate: true
    },
    'currentIsCollections': {
      async handler (d) {
        // 強制更新畫面
        this.isShowCompanyList = false
        await this.$nextTick()
        this.isShowCompanyList = true
      },
      deep: true
    }
  }
}
</script>
