export default [
  {
    label: '依資本額由高至低',
    value: '{"key":"capital_amount","order":"desc"}'
  },
  {
    label: '依資本額由低至高',
    value: '{"key":"capital_amount","order":"asc"}'
  },
  // {
  //   label: '依登記筆畫由高至低',
  //   value: '{"key":"company_name.keyword","order":"desc"}'
  // },
  // {
  //   label: '依登記筆畫由低至高',
  //   value: '{"key":"company_name.keyword","order":"asc"}'
  // }
  {
    label: '依員工人數由高至低',
    value: '{"key":"jobs.summary.employee_count","order":"desc"}'
  },
  {
    label: '依員工人數由低至高',
    value: '{"key":"jobs.summary.employee_count","order":"asc"}'
  },
  {
    label: '依進口實績由高至低',
    value: '{"key":"imports","order":"desc"}'
  },
  {
    label: '依進口實績由低至高',
    value: '{"key":"imports","order":"asc"}'
  },
  {
    label: '依出口實績由高至低',
    value: '{"key":"exports","order":"desc"}'
  },
  {
    label: '依出口實績由低至高',
    value: '{"key":"exports","order":"asc"}'
  },
]
